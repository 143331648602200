import { FormControlLabel, Grid, Radio, RadioGroup, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { ConnectedProps, connect, useDispatch } from 'react-redux';
import Sex from '../../../../domain/enum/Sex';
import TypeSubmit from '../../../../domain/enum/TypeSubmit';
import InsertEmployeeRequest from '../../../../domain/interface/request/InsertEmployeeRequest';
import UpdateEmployeeRequest from '../../../../domain/interface/request/UpdateEmployeeRequest';
import Employee from '../../../../domain/model/employee/Employee';
import { RootState } from '../../../../setup/reducer/reducers';
import { errorNotificationMessages, modalInformation, notificationIcons, validation } from '../../../utils/constants';
import CustomTextField from '../../atoms/form/CustomTextField';
import BackdropLoading from '../../molecules/backdrop-loading';
import SelectAutocomplete from '../../molecules/select-autocomplete';
import { showNotification } from '../../pages/notification/actions';
import { setLoadingEmployees, setUsers, setUsersModalAction } from '../../pages/users-management/store/actions';
import ConfirmationModal from '../confirmation-modal';
import { changeInsertNewEmployee, changeUpdateEmployee, setDefaultUserModalAction } from './store/action';

const PREFIX = 'UsersModal';

const classes = {
  spaced: `${PREFIX}-spaced`,
  helperIcon: `${PREFIX}-helperIcon`,
  descriptionLoading: `${PREFIX}-descriptionLoading`,
  circularProgress: `${PREFIX}-circularProgress`,
  grid: `${PREFIX}-grid`,
  texField: `${PREFIX}-texField`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.spaced}`]: {
    marginBottom: theme.spacing(1.3)
  },
}));

const mapState = (state: RootState) => ({
  employee: state.usersPage.employee
});

const connector = connect(mapState);

type Props = ConnectedProps<typeof connector> & {
  openModal: boolean;
  employee?: Employee;
  typeSubmit: string;
};

let errorEmail = ''
let errorCpf = ''

const UsersModal = (props: Props) => {
  let {
    openModal,
    employee,
    typeSubmit
  } = props;

  const userName = employee?.name
  const userCpf = employee?.cpf
  const userSex = sexUserDefault(employee?.sex)
  const userEmail = employee?.email
  const userPosition = employee?.position
  const userCanSigner = employee?.canSignDocuments

  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidCpf, setIsInvalidCpf] = useState(false);
  const [isInvalidSex, setIsInvalidSex] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidPosition, setIsInvalidPosition] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const listSex = ['Masculino', 'Feminino']
  const dispatch = useDispatch();

  const [isOldUserSigner, setIsOldUserSigner] = useState<boolean | undefined>(true);

  function disableRadioButton(): boolean {
    if (isOldUserSigner === true && userCanSigner === true) {
      return true
    }
    return false
  };

  function sexUserDefault(sex?: string) {
    if (sex === 'M' || sex === 'Masculino') {
      return 'Masculino';
    } else if (sex === 'F' || sex === 'Feminino') {
      return 'Feminino';
    } else {
      return undefined;
    }
  }


  function handleChangeName(event: any): void {
    const nameInput: any = {
      ...employee,
      name: event.target.value
    };
    dispatch(setDefaultUserModalAction(nameInput));
  };

  function handleChangeCpf(event: any): void {
    let cpfPen = event.target.value.replace(/\D/g, '')

    if (event.target.value.length <= 14) {

      let formatCpf = ""

      if (cpfPen.length <= 3) {
        formatCpf = cpfPen
      } else if (cpfPen.length <= 6) {
        formatCpf = `${cpfPen.slice(0, 3)}.${cpfPen.slice(3)}`;
      } else if (cpfPen.length <= 9) {
        formatCpf = `${cpfPen.slice(0, 3)}.${cpfPen.slice(3, 6)}.${cpfPen.slice(6)}`;
      } else {
        formatCpf = `${cpfPen.slice(0, 3)}.${cpfPen.slice(3, 6)}.${cpfPen.slice(6, 9)}-${cpfPen.slice(9, 11)}`;
      }
      const cpfInput: any = {
        ...employee,
        cpf: formatCpf
      };
      dispatch(setDefaultUserModalAction(cpfInput));
    };
  };

  function handleChangeSex(value: any): void {
    const sexInput: any = {
      ...employee,
      sex: value
    };
    dispatch(setDefaultUserModalAction(sexInput));
  };

  function handleChangeEmail(event: any): void {
    const emailInput: any = {
      ...employee,
      email: event.target.value
    };
    dispatch(setDefaultUserModalAction(emailInput));
  };

  function handleChangePosition(event: any): void {
    const positionInput: any = {
      ...employee,
      position: event.target.value
    };
    dispatch(setDefaultUserModalAction(positionInput));
  };

  function handleChangeTypeSigner(event: any): void {
    let canSignerType = event.target.value;
    canSignerType = canSignerType === 'true' ? true : false
    setIsOldUserSigner(userCanSigner);
    const signerInput: any = {
      ...employee,
      canSignDocuments: canSignerType
    };
    dispatch(setDefaultUserModalAction(signerInput));
  };


  function validationUserSubmit(user: UpdateEmployeeRequest) {
    let validForms = true;

    validForms = validateUserName(user, validForms);
    validForms = validateUserCpf(user, validForms);
    validForms = validateUserSex(user, validForms);
    validForms = validateUserEmail(user, validForms);
    validForms = validateUserPosition(user, validForms);

    return validForms;
  };

  function validateUserName(user: UpdateEmployeeRequest, validForms: boolean) {
    if (user.name.length === 0) {
      setIsInvalidName(true);
      validForms = false;
    } else {
      setIsInvalidName(false);
    }
    return validForms;
  }

  function validateUserCpf(user: UpdateEmployeeRequest, validForms: boolean) {
    if (!user.cpf || user.cpf.length < 11) {
      errorCpf = user.cpf === undefined
        ? validation.errorMessages.requiredField
        : validation.errorMessages.invalidCpf;
      setIsInvalidCpf(true);
      validForms = false;
    } else {
      setIsInvalidCpf(false);
    }
    return validForms;
  }

  function validateUserSex(user: UpdateEmployeeRequest, validForms: boolean) {
    if (!user.sex) {
      setIsInvalidSex(true);
      validForms = false;
    } else {
      setIsInvalidSex(false);
    }
    return validForms;
  }

  function validateUserEmail(user: UpdateEmployeeRequest, validForms: boolean) {
    const emailPen = user.email
    const domain = 'animaeducacao.com';
    const regex = new RegExp(`^[a-zA-Z0-9._-]+@${domain}\\.[a-zA-Z]{2,}$`);

    if (user.email.length === 0 || !regex.test(emailPen)) {
      setIsInvalidEmail(true);
      errorEmail = user.email.length === 0
        ? validation.errorMessages.requiredField
        : modalInformation.modalEditUsers.animaEmail;
      validForms = false;
    } else {
      setIsInvalidEmail(false);
    }
    return validForms;
  }

  function validateUserPosition(user: UpdateEmployeeRequest, validForms: boolean) {
    if (user.position.length === 0) {
      setIsInvalidPosition(true);
      validForms = false;
    } else {
      setIsInvalidPosition(false);
    }
    return validForms;
  }

  function validationInputCpf(): boolean {
    if (typeSubmit === TypeSubmit.NewUserModal) {
      return false
    }
    return employee?.cpf ? true : false
  }

  function validationChangeRequest(sex: string) {
    return sex === 'Masculino' ? Sex.Male : Sex.Female
  };

  function styleCpf(cpf?: string): string | undefined {
    if (typeSubmit === TypeSubmit.EditUserModal && cpf !== undefined) {
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }
    return cpf
  }

  function createRequestInsert(user: UpdateEmployeeRequest) {
    const sexRequest = validationChangeRequest(user.sex)
    const loginAndRegister = user.email.split('@');
    const cpfRequest = user.cpf.replace(/[.-]/g, '');

    const changeRequest: InsertEmployeeRequest = {
      name: user.name,
      email: user.email,
      cpf: cpfRequest,
      sex: sexRequest,
      position: user.position,
      canSignDocuments: user.canSignDocuments,
      login: loginAndRegister[0],
      registerNumber: loginAndRegister[0],
      group: {
        "*": {
          "*": "NCAOperator"
        }
      }
    };
    return changeRequest
  }

  async function handleSubmit() {
    try {
      const user: any = {
        name: userName,
        email: userEmail,
        cpf: userCpf,
        sex: userSex,
        position: userPosition,
        canSignDocuments: userCanSigner
      };

      if (validationUserSubmit(user)) {
        setLoading(true)
        let res: any;
        const hasUndefinedValue = !Object.values(user).some((value) => value === undefined)

        if (hasUndefinedValue) {
          const changeRequest: UpdateEmployeeRequest = {
            name: user.name,
            email: user.email,
            cpf: user.cpf.replace(/[.-]/g, ''),
            sex: validationChangeRequest(user.sex),
            position: user.position,
            canSignDocuments: user.canSignDocuments
          };

          if (typeSubmit === TypeSubmit.EditUserModal) {
            res = await dispatch(changeUpdateEmployee(changeRequest))
          } else if (typeSubmit === TypeSubmit.NewUserModal) {
            res = await dispatch(changeInsertNewEmployee(createRequestInsert(user)))
            if (res.cpf) {
              errorCpf = validation.errorMessages.invalidCpf
              setIsInvalidCpf(true);
              res = false
            } else {
              setIsInvalidCpf(false);
            }
          };
        };

        if (res) {
          setIsOldUserSigner(true)
          clearDatesEmployee()
          setLoading(false)
          reloadEmployee()
        } else {
          setLoading(false)
        }
      };
    } catch (error) {
      setLoading(false)
      dispatch(
        showNotification(
          errorNotificationMessages.defaultError,
          notificationIcons.error
        ))
    }

  };

  function clearDatesEmployee() {
    const cancelEmployee = {
      name: '',
      email: '',
      cpf: '',
      sex: '',
      position: '',
      canSignDocuments: false,
      login: '',
      registerNumber: '',
      institutions: {},
      id: ''
    };

    dispatch(setDefaultUserModalAction(cancelEmployee as Employee));
  }

  function handleClickCancel(): void {
    setIsInvalidName(false);
    setIsInvalidCpf(false);
    setIsInvalidSex(false);
    setIsInvalidEmail(false);
    setIsInvalidPosition(false);
    clearDatesEmployee()
    dispatch(setUsersModalAction(false, ''));
  };

  async function reloadEmployee(): Promise<any> {
    dispatch(setLoadingEmployees(true));
    dispatch(setUsersModalAction(false, ''));
    return dispatch(setUsers());
  };

  return (
    <>
      <ConfirmationModal
        openModal={openModal}
        modalTitle={typeSubmit === TypeSubmit.NewUserModal ? modalInformation.modalEditUsers.newTitle : modalInformation.modalEditUsers.title}
        textContinueButton={modalInformation.modalEditUsers.textContinue}
        handleContinueButton={handleSubmit}
        textCancelButton={modalInformation.modalEditUsers.textCancel}
        handleCancelButton={handleClickCancel}
      >
        <StyledGrid
        >
          {isLoading && (
            <BackdropLoading
              size={50}
              description={typeSubmit === TypeSubmit.EditUserModal ? 'Editando Informações...' : 'Cadastrando usuário...'}
              open={isLoading}
              classes={classes}
            />
          )}

          <Grid item xs={12}>
            <Typography className={classes.spaced}>
              {modalInformation.modalEditUsers.description}
            </Typography>
            <Typography variant="caption">Nome</Typography>
            <CustomTextField
              placeholder="Digite o nome do usuário..."
              type="text"
              fullWidth
              value={userName}
              onChange={handleChangeName}
              required
              helperText={
                isInvalidName && validation.errorMessages.requiredField
              }
              error={isInvalidName}
            />
            <Typography className={classes.spaced} />
            <Typography variant="caption">CPF</Typography>
            <CustomTextField
              placeholder="Digite o CPF do usuário..."
              type="text"
              fullWidth
              value={styleCpf(userCpf)}
              size="small"
              onChange={handleChangeCpf}
              disabled={validationInputCpf()}
              required
              helperText={
                isInvalidCpf && errorCpf
              }
              error={isInvalidCpf}

            />
            <Typography className={classes.spaced} />
            <Typography variant="caption">Sexo</Typography>
            <SelectAutocomplete
              listItems={listSex}
              defaultValue={userSex}
              placeholder="Escolha o sexo..."
              setItem={handleChangeSex}
              data-testid="sex-users"
              helperText={
                !isInvalidSex ? '' : validation.errorMessages.requiredField
              }
              error={isInvalidSex}
            />
            <Typography className={classes.spaced} />
            <Typography variant="caption">E-mail</Typography>
            <CustomTextField
              placeholder="Digite o e-mail do usuário..."
              type="email"
              fullWidth
              value={userEmail}
              onChange={handleChangeEmail}
              required
              helperText={
                isInvalidEmail && errorEmail
              }
              error={isInvalidEmail}
            />
            <Typography className={classes.spaced} />
            <Typography variant="caption">Cargo</Typography>
            <CustomTextField
              placeholder="Digite o cargo do usuário..."
              type="text"
              fullWidth
              value={userPosition}
              onChange={handleChangePosition}
              helperText={
                isInvalidPosition && validation.errorMessages.requiredField
              }
              error={isInvalidPosition}
            />
            <Typography className={classes.spaced} />
            <Typography variant="caption">Assinante</Typography>
            <RadioGroup
              name="employee-signer"
              value={userCanSigner ? true : false}
              onChange={handleChangeTypeSigner}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label={'Sim'}
              />
              <FormControlLabel
                value={false}
                disabled={disableRadioButton()}
                control={<Radio size="small" />}
                label={'Não'}
              />
            </RadioGroup>
          </Grid>
        </StyledGrid>
      </ConfirmationModal>
    </>
  );
};

export default connector(UsersModal);
