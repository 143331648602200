import CurriculumStatus from '../../../../domain/enum/curriculum/CurriculumStatus';
import OriginSystem from '../../../../domain/enum/curriculum/OriginSystem';
import Root from '../../../../domain/model/curriculum/Root';
import {
  CYAN_COLOR,
  DARK_CYAN_COLOR,
  DIPLOMA_VALIDATION_ACTIVE_ACTION,
  INFO_COLOR,
  PINK_NEON_COLOR,
  PURPLE_VIOLET_COLOR,
  TOMATO_COLOR,
  DARK_GRAY_COLOR
} from '../../../../theme';
import { SelectAutocompleteMultipleCheckboxesOption } from '../../molecules/select-autocomplete-multiple-checkboxes/types';
import { CurriculumsFilter } from './store/types';
import {
  CurriculumData,
  SelectedCourse,
  SelectedOriginSystem,
  SelectedStatus
} from './types';

export const CurriculumOriginSystemList: SelectedOriginSystem[] = [
  {
    name: 'Todos',
    id: ''
  },
  {
    name: OriginSystem.SIAF,
    id: OriginSystem.SIAF
  },
  {
    name: OriginSystem.SIGADL,
    id: OriginSystem.SIGADL
  },
  {
    name: OriginSystem.SIGA,
    id: OriginSystem.SIGA
  },
  {
    name: OriginSystem.SIGAF2F,
    id: OriginSystem.SIGAF2F
  }
];

export const mapCoursesToOptions = (
  curriculumsList: Root[]
): SelectAutocompleteMultipleCheckboxesOption[] => {
  return [...new Set(curriculumsList.map((c) => c.courseName))]
    .sort()
    .map((c) => {
      return {
        name: c,
        value: c
      };
    });
};

export const CurriculumStatusList: SelectAutocompleteMultipleCheckboxesOption[] =
  [
    {
      name: 'GERANDO XML DO CURRÍCULO DIGITAL',
      value: CurriculumStatus.GeneratingCurriculumXml,
      color: PINK_NEON_COLOR
    },
    {
      name: 'AGUARDANDO ASSINATURA DO XML',
      value: CurriculumStatus.AwaitingXmlSignature,
      color: DIPLOMA_VALIDATION_ACTIVE_ACTION
    },
    {
      name: 'GERANDO REPRESENTAÇÃO VISUAL DO CURRÍCULO DIGITAL',
      value: CurriculumStatus.GeneratingVisualRepresentation,
      color: PURPLE_VIOLET_COLOR
    },
    {
      name: 'CONCLUÍDO',
      value: CurriculumStatus.Completed,
      color: DARK_CYAN_COLOR
    },
    {
      name: 'REPROVADO',
      value: CurriculumStatus.Rejected,
      color: TOMATO_COLOR
    },
    {
      name: 'PENDENTE DE CONFIRMAÇÃO DE EMISSÃO',
      value: CurriculumStatus.IssuerConfirmationPending,
      color: INFO_COLOR
    },
    {
      name: 'EM SINCRONIZAÇÃO',
      value: CurriculumStatus.Synchronizing,
      color: CYAN_COLOR
    },
    {
      name: 'REINICIANDO FLUXO',
      value: CurriculumStatus.RestartingFlow,
      color: DARK_GRAY_COLOR
    }
  ];

const isRightOriginSystem = (
  curriculum: Root,
  originSystem: SelectedOriginSystem
) => {
  return (
    !originSystem ||
    !originSystem.id ||
    curriculum.originSystem === originSystem.id
  );
};

const isRightCourse = (curriculum: Root, courses: SelectedCourse[]) => {
  return (
    courses.length === 0 ||
    !!courses.find((course) => course.name === curriculum.courseName)
  );
};

const isRightStatus = (curriculum: Root, statuses: SelectedStatus[]) => {
  return (
    statuses.length === 0 ||
    !!statuses.find((status) => status.value === curriculum.status)
  );
};

export const getRows = (
  curriculums: Root[],
  filter: CurriculumsFilter
): CurriculumData[] => {
  let rows: CurriculumData[] = [];
  const { originSystem, courses, statuses } = filter;

  if (!curriculums || curriculums.length === 0) {
    return rows;
  }

  curriculums.forEach((value) => {
    if (
      isRightOriginSystem(value, originSystem) &&
      isRightCourse(value, courses) &&
      isRightStatus(value, statuses)
    ) {
      rows.push({
        id: value.pk,
        status: value.status,
        checkbox: value.checkbox,
        courseName: value.courseName,
        originSystem: value.originSystem,
        curriculumCode: value.curriculumCode ?? '',
        xsdVersion: value.xsdVersion,
        nameForAreas: value.nameForAreas,
        scheduleCode: value.scheduleCode,
        scheduleVersion: value.scheduleVersion,
        updatedAt: value.updatedAt,
        action: '',
        reasonType: value.reasonType ?? '',
        campusEmecCode: value.campusEmecCode,
        campusName: value.campusName,
        validationCode: value.validationCode ?? '',
        issuerMecCode: value.issuerMecCode ?? '',
        curriculumDescription: value.curriculumDescription
      });
    }
  });

  return rows;
};
