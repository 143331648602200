import { styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import TypographyWithError from "../typography-with-error";
import { formatPrerequisites, handleRequestSort, orderList } from "../../../utils/functions/curriculum";
import EnhancedTableHead from "../../organisms/enhanced-table-head";
import { HeadCell, Order } from "../../organisms/enhanced-table-head/types";
import { CurriculumTableProps } from "./types";
import { useState } from "react";


const PREFIX = 'CurricularUnitiesLegacyTable';

const classes = {
  activeSortIcon: `${PREFIX}-activeSortIcon`,
  inactiveSortIcon: `${PREFIX}-inactiveSortIcon`
};

const StyledTable = styled(Table)((
  {
    theme
  }
) => ({
  [`& .${classes.activeSortIcon}`]: {
    fill: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  [`& .${classes.inactiveSortIcon}`]: {
    width: 24,
    height: 24,
  }
}));

const LegacyCurriculumTable = ({ curricularUnityAndValidation, pageStartIndex }: CurriculumTableProps) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('stage');

  const pageSize = 10;

  const headCells = [
    {
      id: 'type',
      label: 'Tipo',
      size: '15%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'code',
      label: 'Código',
      size: '15%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'name',
      label: 'Disciplina',
      size: '25%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'stage',
      label: 'Fase',
      size: '10%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'prerequisites',
      label: 'Pré-requisitos',
      size: '20%',
      align: 'left',
      sortable: true,
      styles: {}
    },
    {
      id: 'load',
      label: 'CH relógio',
      size: '15%',
      align: 'right',
      sortable: true,
      styles: {}
    }
  ];

  return (
    <StyledTable>
      <EnhancedTableHead
        classes={{ activeSortIcon: classes.activeSortIcon, inactiveSortIcon: classes.inactiveSortIcon }}
        onRequestSort={(_, property) => handleRequestSort(property, orderBy, order, setOrder, setOrderBy)}
        order={order}
        orderBy={orderBy}
        headCells={headCells as HeadCell[]}
      />
      <TableBody>
        {orderList(curricularUnityAndValidation, order, orderBy).slice(pageStartIndex, pageStartIndex + pageSize).map((curricularUnity, index) => {
          return (
            <TableRow
              key={`${curricularUnity.code}${index}`}
            >
              <TableCell component="th" scope="row">
                <TypographyWithError
                  value={curricularUnity?.type || 'N/A'}
                  errors={curricularUnity?.validation?.type} />
              </TableCell>
              <TableCell>
                <TypographyWithError
                  value={curricularUnity?.code || 'N/A'}
                  errors={curricularUnity?.validation?.code} />
              </TableCell>
              <TableCell>
                <TypographyWithError
                  value={curricularUnity?.name || 'N/A'}
                  errors={curricularUnity?.validation?.name} />
              </TableCell>
              <TableCell>
                <TypographyWithError
                  value={curricularUnity?.stage || 'N/A'}
                  errors={curricularUnity?.validation?.stage} />
              </TableCell>
              <TableCell>
                <TypographyWithError
                  value={formatPrerequisites(curricularUnity)}
                  errors={curricularUnity?.validation?.prerequisites} />
              </TableCell>
              <TableCell align='right'>
                <TypographyWithError
                  value={curricularUnity?.curricularUnitClockTime?.load ? `${curricularUnity.curricularUnitClockTime.load}h` : 'N/A'}
                  errors={curricularUnity?.validation?.curricularUnitClockTime?.load} />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
}
export default LegacyCurriculumTable;
